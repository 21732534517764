/**
 * API requests focused around Campaigns.
 */
import { circularApiTypes } from "./circularApiTypes";
import axios, { AxiosResponse } from "axios";
import * as circularApiRequestMocks from "./circularApiRequests.mock";
import {
    extractCircularApiEnvironmentUrl,
    extractEventsApiEnvironmentUrl,
} from "../util";

/**
 * Makes an API call to GraphQL to get the circular data.
 * @param requestInfo - The request inputs.
 * @param isUnitTest - If true, this method will return a mocked data set.
 * @returns a promise containing domain locations data.
 */
export function getCircularData(
    requestInfo: circularApiTypes.requestModels.CircularRequest,
    isUnitTest?: boolean
): Promise<AxiosResponse<circularApiTypes.responseModels.CircularResponse>> {
    return isUnitTest
        ? circularApiRequestMocks.getCircularData()
        : axios(
              `${extractCircularApiEnvironmentUrl(
                  BUILD_ENV
              )}/circulars/graphql`,
              {
                  method: "POST",
                  data: {
                      query: `
                        {
                            circular(id: ${requestInfo.circularId}) {
                                id
                                campaignId
                                flightId
                                logoURL
                                createdAt
                                lastEditedAt
                                startDate
                                endDate
                                location {
                                    id
                                    brandId
                                    name
                                    address1
                                    address2
                                    city
                                    state
                                    zipcode
                                    country
                                    latitude
                                    longitude
                                    distance
                                    createdAt
                                    lastEditedAt
                                }
                                items(preview: ${requestInfo.preview}) {
                                    id
                                    trackingId
                                    promoId
                                    upc
                                    sku
                                    brand
                                    category
                                    size
                                    title
                                    price {
                                        amount
                                        currency
                                    }
                                    salePrice {
                                        amount
                                        currency
                                    }
                                    featured
                                    promoCategory
                                    promoText
                                    promoStartDt
                                    promoEndDt
                                    imageUrl
                                    itemUrl
                                    createdAt
                                    lastEditedAt
                                }
                            }
                       }
                  `,
                  },
                  headers: {
                      accept: "application/json",
                  },
              }
          );
}

/**
 * Makes an API call to schedule the add to list event for the item for out-of-app.
 * @param requestInfo - The request inputs.
 * @param isUnitTest - If true, this method will return a mocked data set.
 * @returns a promise.
 */
export function scheduleOutOfAppItemAddToList(
    requestInfo: circularApiTypes.requestModels.ScheduleOutOfAppItemAddToListRequest,
    isUnitTest?: boolean
): Promise<
    AxiosResponse<circularApiTypes.responseModels.ScheduleOutOfAppItemAddToListResponse>
> {
    return isUnitTest
        ? circularApiRequestMocks.scheduleOutOfAppItemAddToList()
        : axios(
              `${extractCircularApiEnvironmentUrl(
                  BUILD_ENV
              )}/circulars/graphql`,
              {
                  method: "POST",
                  data: {
                      query: `
                        mutation payload($input: PayloadInput!) {
                            payload(input: $input) {
                                status
                            }
                        }
                    `,
                      variables: {
                          input: {
                              ...requestInfo,
                          },
                      },
                  },
                  headers: {
                      accept: "application/json",
                  },
              }
          );
}

/**
 * Makes an API call to send the circular event for reporting.
 * @param requestInfo - The request inputs.
 * @param isUnitTest - If true, this method will return a mocked data set.
 * @returns a promise.
 */
export function sendCircularEvent(
    requestInfo: circularApiTypes.requestModels.SendCircularEventRequest,
    isUnitTest?: boolean
): Promise<
    AxiosResponse<circularApiTypes.responseModels.SendCircularEventResponse>
> {
    return isUnitTest
        ? circularApiRequestMocks.sendCircularEvent()
        : axios(extractEventsApiEnvironmentUrl(BUILD_ENV), {
              method: "POST",
              data: requestInfo,
              headers: {
                  accept: "application/json",
              },
          });
}
