/**
 * Defines the {@link Header} class.
 * @module
 */
import * as React from "react";
import "./Header.scss";
import { HeaderDefinition } from "../../types";
import MapPinIcon from "@material-ui/icons/LocationOn";
import moment = require("moment-timezone");
import Button from "@material-ui/core/Button";

/**
 * Props interface for {@link Header}.
 */
interface Props {
    /**
     * The header information for the circular.
     */
    headerInfo: HeaderDefinition;
}

/**
 * State interface for {@link Header}.
 */
interface State {}

/**
 * Creates the circular Header component.
 */
export class Header extends React.Component<Props, State> {
    /**
     * @inheritDoc
     */
    constructor(props: Props, context?: any) {
        super(props, context);
    }

    /**
     * @inheritDoc
     */
    public render(): JSX.Element {
        return (
            <div className="Header">
                <div className="main-logo-container">
                    <img
                        className="main-logo"
                        src={this.props.headerInfo.logoUrl}
                        alt={""}
                        draggable={false}
                    />
                </div>
                <div className="circular-info">
                    <div className="location-info-container">
                        <div className="column-spacer" />
                        <div>
                            <Button
                                className="open-map-button"
                                onClick={() => {
                                    window.open(
                                        `https://maps.google.com/?q=${this.props.headerInfo.latitude},${this.props.headerInfo.longitude}`,
                                        "_blank"
                                    );
                                }}
                            >
                                <MapPinIcon />
                                {this.props.headerInfo.storeName}
                            </Button>
                            <div className="address-part-1">
                                {this.props.headerInfo.address}
                            </div>
                            <div className="address-part-2">
                                {`${this.props.headerInfo.city}, ${this.props.headerInfo.state} ${this.props.headerInfo.zipCode}`}
                            </div>
                        </div>
                    </div>
                    <div className="circular-dates-container">
                        <div className="column-spacer" />
                        <div>
                            <div className="row-spacer" />
                            <div>
                                <div>Valid:</div>
                                <div>
                                    {`${moment(
                                        this.props.headerInfo.startDate
                                    ).format("MM/DD/YY")} - ${moment(
                                        this.props.headerInfo.endDate
                                    ).format("MM/DD/YY")}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
