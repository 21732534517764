import {
    CircularTemplatesApiEnvironments,
    EventsApiEnvironments,
    nil,
    ObjectMap,
} from "./types";
import { NoInfer } from "type-zoo";

/**
 * Generates a display friendly label based on a certain input format.
 * Example formats:
 *      testlable -> Testlabel
 *      test-label -> Test Label
 *      test-label-long -> Test Label Long
 * @param stringToReplace - The non-formatted string to be formatted.
 * @returns The formatted display friendly string.
 */
export function generateDisplayFriendlyLabel(stringToReplace: string): string {
    return stringToReplace
        .replace(/-/g, " ")
        .replace(/\|/g, " / ")
        .replace(/\b\w/g, (l) => l.toUpperCase());
}

/**
 * Creates a Promise that resolves after a setTimeout delay.
 * @param milliseconds - Number of milliseconds to wait before the
 *      promise is resolved.
 * @returns A promise that is asynchronously resolved after the specified delay.
 */
export function wait(milliseconds: number = 0): Promise<void> {
    return new Promise((resolve) => {
        window.setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}

/**
 * Convenient utility for calling a function that may or may
 * not be undefined/null.
 * Does nothing if the provided function is undefined/null.
 * @param func - The function to be called.
 * @returns The return value of the executed function, or undefined if
 *      the function is null/undefined
 */
export function safeInvoke<R>(func: (() => R) | nil): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 */
export function safeInvoke<A1, R>(
    func: ((arg1: A1) => R) | nil,
    arg1: NoInfer<A1>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 * @param arg2 - Function argument.
 */
export function safeInvoke<A1, A2, R>(
    func: ((arg1: A1, arg2: A2) => R) | nil,
    arg1: NoInfer<A1>,
    arg2: NoInfer<A2>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 * @param arg2 - Function argument.
 * @param arg3 - Function argument.
 */
export function safeInvoke<A1, A2, A3, R>(
    func: ((arg1: A1, arg2: A2, arg3: A3) => R) | nil,
    arg1: NoInfer<A1>,
    arg2: NoInfer<A2>,
    arg3: NoInfer<A3>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 * @param arg2 - Function argument.
 * @param arg3 - Function argument.
 * @param arg4 - Function argument.
 */
export function safeInvoke<A1, A2, A3, A4, R>(
    func: ((arg1: A1, arg2: A2, arg3: A3, arg4: A4) => R) | nil,
    arg1: NoInfer<A1>,
    arg2: NoInfer<A2>,
    arg3: NoInfer<A3>,
    arg4: NoInfer<A4>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 * @param arg2 - Function argument.
 * @param arg3 - Function argument.
 * @param arg4 - Function argument.
 * @param arg5 - Function argument.
 */
export function safeInvoke<A1, A2, A3, A4, A5, R>(
    func: ((arg1: A1, arg2: A2, arg3: A3, arg4: A4, arg5: A5) => R) | nil,
    arg1: NoInfer<A1>,
    arg2: NoInfer<A2>,
    arg3: NoInfer<A3>,
    arg4: NoInfer<A4>,
    arg5: NoInfer<A5>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param arg1 - Function argument.
 * @param arg2 - Function argument.
 * @param arg3 - Function argument.
 * @param arg4 - Function argument.
 * @param arg5 - Function argument.
 * @param arg6 - Function argument.
 */
export function safeInvoke<A1, A2, A3, A4, A5, A6, R>(
    func:
        | ((arg1: A1, arg2: A2, arg3: A3, arg4: A4, arg5: A5, arg6: A6) => R)
        | nil,
    arg1: NoInfer<A1>,
    arg2: NoInfer<A2>,
    arg3: NoInfer<A3>,
    arg4: NoInfer<A4>,
    arg5: NoInfer<A5>,
    arg6: NoInfer<A6>
): R | undefined;
/**
 * See main definition above.
 * @param func - The function to call.
 * @param args - All arguments to call with the function.
 * @returns the method to be called if its defined.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function safeInvoke(func: Function | nil, ...args: any[]): any {
    if (func) {
        return func(...args);
    }
}

/**
 * Converts a currency value from cents to dollars.
 * @param centsValue - The money value in cents.
 * @returns the dollar value based on the cents value input.
 */
export function convertCurrencyFromCents(centsValue: number): number {
    return centsValue / 100;
}

/**
 * Converts a currency value from dollars to cents.
 * @param dollarValue - The money value in dollars.
 * @returns the cents value based on the dollars value input.
 */
export function convertCurrencyToCents(dollarValue: number): number {
    return dollarValue * 100;
}

/**
 * Take a money amount as a pure number and
 * converts it to a formatted currency string.
 * @param amount - The pure number money value.
 * @returns the formatted currency value.
 */
export function formatToCurrency(amount: number): string {
    const separator = ",";
    const negativeSign = amount < 0 ? "-" : "";
    const formattedAmount = Math.abs(Math.floor(amount * 100) / 100).toFixed(2);
    let separatorCount;

    if (amount.toString().indexOf(".") !== -1) {
        const tempAmount = amount
            .toString()
            .substr(0, amount.toString().indexOf("."));
        separatorCount = tempAmount.length > 3 ? formattedAmount.length % 3 : 0;
    } else {
        separatorCount =
            amount.toString().length > 3 ? formattedAmount.length % 3 : 0;
    }

    return `${negativeSign}$${
        (separatorCount
            ? formattedAmount.substr(0, separatorCount) + separator
            : "") +
        formattedAmount
            .substr(separatorCount)
            .replace(/(\\d{3})(?=\\d)/g, "$1" + separator)
    }`;
}

/**
 * Extracts the Environment URL needed for Circular API calls.
 * @param appEnv - The app environment.
 * @returns the URL for the API based on the app environment.
 */
export function extractCircularApiEnvironmentUrl(appEnv: string): string {
    let envUrl = CircularTemplatesApiEnvironments.PRODUCTION;

    if (appEnv === "prod_mirror") {
        envUrl = CircularTemplatesApiEnvironments.PRODUCTION_MIRROR;
    } else if (appEnv === "stg") {
        envUrl = CircularTemplatesApiEnvironments.STAGING;
    } else if (appEnv === "dev") {
        envUrl = CircularTemplatesApiEnvironments.DEVELOPMENT;
    }

    return envUrl;
}

/**
 * Extracts the Environment URL needed for Circular Event API calls.
 * @param appEnv - The app environment.
 * @returns the URL for the API based on the app environment.
 */
export function extractEventsApiEnvironmentUrl(appEnv: string): string {
    let envUrl = EventsApiEnvironments.PRODUCTION;

    if (appEnv === "prod_mirror") {
        envUrl = EventsApiEnvironments.PRODUCTION_MIRROR;
    } else if (appEnv === "stg") {
        envUrl = EventsApiEnvironments.STAGING;
    } else if (appEnv === "dev") {
        envUrl = EventsApiEnvironments.DEVELOPMENT;
    }

    return envUrl;
}

/**
 * Extracts all query parameters and values
 * within the provided URL "search" string.
 * @returns an object containing all query string key/value entries.
 */
export function extractUrlQueryParams(): ObjectMap<string> {
    const keyValueStringList: string[] = window.location.search
        .substr(1)
        .split("&");
    const queryParamsObj: ObjectMap<string> = {};

    for (const keyValueString of keyValueStringList) {
        const keyValueList = keyValueString.split("=", 2);

        if (keyValueList.length === 1) {
            queryParamsObj[keyValueList[0]] = "";
        } else {
            queryParamsObj[keyValueList[0]] = decodeURIComponent(
                keyValueList[1].replace(/\+/g, " ")
            );
        }
    }

    return queryParamsObj;
}
