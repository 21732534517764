/**
 * Defines the {@link LoadingIndicator} class.
 * @module
 */
import * as React from "react";
import "./LoadingIndicator.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Props interface for {@link LoadingIndicator}.
 */
interface Props {
    /**
     * The label that will be displayed along side the loading indicator.
     */
    label?: string;
    /**
     * The size of the loading indicator label.
     * If no value is provided,
     * {@link LoadingIndicatorNamespace.LabelSize.MEDIUM} will be used.
     */
    labelSize?: LoadingIndicatorNamespace.LabelSize;
    /**
     * The size of the loading indicator.
     * If no value is provided,
     * {@link LoadingIndicatorNamespace.IndicatorSize.MEDIUM} will be used.
     */
    indicatorSize?: LoadingIndicatorNamespace.IndicatorSize;
}

/**
 * State interface for {@link LoadingIndicator}.
 */
interface State {}

/**
 * Creates a generic loading indicator.
 */
export class LoadingIndicator extends React.Component<Props, State> {
    /**
     * @inheritDoc
     */
    constructor(props: Props, context?: any) {
        super(props, context);
    }

    /**
     * @inheritDoc
     */
    public render(): JSX.Element {
        return (
            <div className="LoadingIndicator">
                {this.props.label ? (
                    <label
                        className={`loading-indicator-label ${
                            this.props.labelSize
                                ? this.props.labelSize
                                : LoadingIndicatorNamespace.LabelSize.MEDIUM
                        }`}
                    >
                        {this.props.label}
                    </label>
                ) : undefined}
                <CircularProgress
                    className="loading-indicator"
                    size={
                        this.props.indicatorSize ||
                        LoadingIndicatorNamespace.IndicatorSize.MEDIUM
                    }
                />
            </div>
        );
    }
}

/**
 * The namespace for the {@link LoadingIndicator} component.
 */
export namespace LoadingIndicatorNamespace {
    /**
     * An enumeration containing all possible loading indicator sizes.
     */
    export enum IndicatorSize {
        /**
         * Small sized loading indicator.
         */
        SMALL = 24,
        /**
         * Medium sized loading indicator.
         */
        MEDIUM = 36,
        /**
         * Large sized loading indicator.
         */
        LARGE = 48,
    }
    /**
     * An enumeration containing all possible loading indicator label sizes.
     */
    export enum LabelSize {
        /**
         * Small sized loading indicator label font.
         */
        SMALL = "loading-indicator-label-small",
        /**
         * Medium sized loading indicator label font.
         */
        MEDIUM = "loading-indicator-label-medium",
        /**
         * Large sized loading indicator label font.
         */
        LARGE = "loading-indicator-label-large",
    }
}
