import * as React from "react";
import * as ReactDOM from "react-dom";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core";
import { Circular } from "./components/Circular";
import "./index.scss";

// Polyfill to allow support for Promise in IE.
require("es6-promise/auto");

/**
 * Function that renders the main app.
 * @returns the JSX representing the main app.
 */
function MainApp(): JSX.Element {
    const finalFontFamilies: string[] = [];

    finalFontFamilies.push("Montserrat");
    finalFontFamilies.push("Helvetica");
    finalFontFamilies.push("Arial");
    finalFontFamilies.push("sans-serif");

    return (
        <MuiThemeProvider
            theme={createMuiTheme({
                typography: {
                    fontFamily: finalFontFamilies.join(","),
                },
            })}
        >
            <Circular />
        </MuiThemeProvider>
    );
}

/**
 * Renders the app.
 */
const render = () => {
    ReactDOM.render(<MainApp />, document.getElementById("root"));
};

render();
