/**
 * Defines the {@link Circular} class.
 * @module
 */
import * as React from "react";
import "./Circular.scss";
import { Header } from "./Header/Header";
import { StandardCircular } from "./StandardCircular/StandardCircular";
import { LoadingIndicator } from "./LoadingIndicator/LoadingIndicator";
import * as circularApiRequests from "../api/circularApiRequests";
import { extractUrlQueryParams, safeInvoke } from "../util";
import {
    HeaderDefinition,
    ItemDefinition,
    AddItemToListConsoleWarnInputs,
} from "../types";

/**
 * Props interface for {@link Circular}.
 */
interface Props {
    /**
     * If true, this component is being loaded in unit testing mode.
     */
    isUnitTest?: boolean;
    /**
     * Triggered when the "add to list" feature completes.
     * For unit testing purposes only.
     * @param platform - The platform the method completed for.
     * @param isInNetwork - If true, the action was performed "in app".
     */
    onAddToListComplete?(platform: string, isInApp: boolean): void;
}

/**
 * State interface for {@link Circular}.
 */
interface State {
    /**
     * The header information for the circular.
     */
    headerInfo: HeaderDefinition | undefined;
    /**
     * The list of items to display for the circular.
     */
    items: ItemDefinition[] | undefined;
    /**
     * Tracks items added to list.
     */
    itemsInList: boolean[];
    /**
     * Tracks the window width.
     */
    windowWidth: number;
    /**
     * The available URL params.
     */
    urlParams: UrlParams;
}

/**
 * The URL query string parameters.
 */
interface UrlParams {
    /**
     * The circular ID.
     */
    circularId: string | undefined;
    /**
     * The store ID.
     */
    storeId: string | undefined;
    /**
     * The user ID.
     */
    udid: string | undefined;
    /**
     * The app name.
     */
    app: string | undefined;
    /**
     * The platform the circular was loaded on.
     */
    plt: string | undefined;
    /**
     * If true, the circular was loaded in-app.
     * If false, the circular was loaded out-of-app.
     */
    inApp: boolean;
    /**
     * If true, the circular is being loaded in "preview" mode.
     * This mode will not send GA requests or AA payload/event requests.
     */
    preview?: boolean;
}

/**
 * The definition of a Detailed List Item.
 */
export interface DetailedListItem {
    /**
     * The barcode of the product.
     */
    product_barcode: string;
    /**
     * The brand of the product.
     */
    product_brand: string;
    /**
     * The category of the product.
     */
    product_category: string;
    /**
     * The discount given for the product.
     */
    product_discount: string;
    /**
     * The image used for display of the product.
     */
    product_image: string;
    /**
     * The SKU of the product.
     */
    product_sku: string;
    /**
     * The name/title of the product.
     */
    product_title: string;
}

/**
 * All possible event names that can be triggered.
 */
enum EventName {
    /**
     * Event name for adding an item to list.
     */
    ADDED_TO_LIST = "added_to_list",
    /**
     * Event name for viewing the circular.
     */
    VIEWED = "viewed",
    /**
     * Event name for queueing and item with the payload scheduler.
     */
    ITEM_QUEUED = "item_queued",
    /**
     * Event name for out-of-network referrals.
     */
    REFERRAL = "referral",
}

/**
 * Creates the main Circular component.
 */
export class Circular extends React.Component<Props, State> {
    /**
     * @inheritDoc
     */
    constructor(props: Props, context?: any) {
        super(props, context);

        const extractedUrlParams = extractUrlQueryParams();
        const finalUrlParams: UrlParams = {
            circularId: extractedUrlParams["cir"],
            storeId: extractedUrlParams["str"],
            udid: extractedUrlParams["ifa"],
            app: extractedUrlParams["app"],
            plt: extractedUrlParams["plt"],
            inApp: extractedUrlParams["inapp"] === "1",
            preview: extractedUrlParams["preview"] === "1",
        };

        this.handleWindowResize = this.handleWindowResize.bind(this);

        this.state = {
            headerInfo: undefined,
            items: undefined,
            itemsInList: [],
            windowWidth: window.innerWidth,
            urlParams: finalUrlParams,
        };
    }

    /**
     * @inheritDoc
     */
    public componentDidMount(): void {
        // This is carry over from the circular_web project.
        // This approach attempts to get the "AdAdapted" object made available
        // by the SDK. The addItemToList method exists as a "bridge" method that
        // when called, sends the data to the native bridge so the item gets
        // added to the user's list. This is being used for Android only currently.
        // @ts-ignore
        if (typeof AdAdapted !== "undefined") {
            // @ts-ignore
            window.AdAdapted = AdAdapted;
        } else {
            // @ts-ignore
            window.AdAdapted = {
                addItemToList(info: AddItemToListConsoleWarnInputs): void {
                    console.warn({
                        circularId: info.circularId,
                        trackingId: info.trackingId,
                        title: info.title,
                        brand: info.brand,
                        category: info.category,
                        upc: info.upc,
                        retailerSku: info.retailerSku,
                        discount: info.discount,
                        image: info.image,
                    });
                },
            };
        }

        // Create the event listener for window resizing.
        window.addEventListener("resize", this.handleWindowResize);

        const queryStringParams = extractUrlQueryParams();

        circularApiRequests
            .getCircularData(
                {
                    circularId: queryStringParams["cir"],
                    preview: queryStringParams["preview"] === "1",
                },
                this.props.isUnitTest
            )
            .then((response) => {
                const circularData = response.data.data.circular;
                const headerDefinition: HeaderDefinition = {
                    storeName: circularData.location.name,
                    address: circularData.location.address1,
                    city: circularData.location.city,
                    state: circularData.location.state,
                    zipCode: circularData.location.zipcode,
                    latitude: circularData.location.latitude,
                    longitude: circularData.location.longitude,
                    startDate: circularData.startDate,
                    endDate: circularData.endDate,
                    logoUrl: circularData.logoURL,
                };
                const itemDefinitionList: ItemDefinition[] = [];
                const itemsInList: boolean[] = [];

                for (const item of circularData.items) {
                    itemDefinitionList.push({
                        title: item.title,
                        additionalDetails: item.size,
                        price: item.salePrice || item.price,
                        isWhileSuppliesLast: false,
                        imageUrl: item.imageUrl,
                        outOfNetworkActionUrl:
                            queryStringParams["plt"].toLowerCase() === "other"
                                ? item.itemUrl
                                : undefined,
                        trackingId: item.trackingId,
                        brand: item.brand,
                        category: item.category,
                        upc: item.upc,
                        sku: item.sku,
                    });
                    itemsInList.push(false);
                }

                if (!this.state.urlParams.preview) {
                    this.sendTrackingEvents(
                        EventName.VIEWED,
                        undefined,
                        undefined
                    );
                }

                this.setState({
                    headerInfo: headerDefinition,
                    items: itemDefinitionList,
                    itemsInList,
                });
            });
    }

    /**
     * @inheritDoc
     */
    public componentWillUnmount(): void {
        // Clean up the event listener.
        window.removeEventListener("resize", this.handleWindowResize);
    }

    /**
     * @inheritDoc
     */
    public render(): JSX.Element {
        return (
            <div className="Circular">
                {this.state.items && this.state.headerInfo ? (
                    <Header headerInfo={this.state.headerInfo} />
                ) : undefined}
                {this.state.items ? (
                    <StandardCircular
                        items={this.state.items}
                        itemsInList={this.state.itemsInList}
                        windowWidth={this.state.windowWidth}
                        onItemClicked={(item, index) => {
                            this.handleOnItemClicked(item, index);
                        }}
                    />
                ) : (
                    <div className="loading-indicator-container">
                        <LoadingIndicator />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Triggered when the window width is resized.
     */
    private handleWindowResize(): void {
        this.setState({
            windowWidth: window.innerWidth,
        });
    }

    /**
     * Triggered when an item's action button is clicked.
     * @param item - The item clicked.
     * @param index - The index of the item clicked.
     */
    private handleOnItemClicked(item: ItemDefinition, index: number): void {
        let eventName: EventName = EventName.ADDED_TO_LIST;

        // Cases covered:
        //     - In-Network
        //         1. In-App
        //             a. iOS
        //             b. Android
        //         2. Out-Of-App
        //     - Out-Of-Network
        if (
            this.state.urlParams.plt &&
            this.state.urlParams.plt.toLowerCase() !== "other"
        ) {
            // The user is in-network.
            if (this.state.urlParams.inApp) {
                const detailedListItem: DetailedListItem = {
                    product_title: item.title,
                    product_brand: item.brand,
                    product_category: item.category,
                    product_barcode: item.upc,
                    product_sku: item.sku,
                    product_discount: "",
                    product_image: item.imageUrl,
                };

                // User is In-App
                if (this.state.urlParams.plt === "ios") {
                    // iOS (Native SDK)
                    try {
                        const url = `content:${btoa(
                            JSON.stringify({
                                detailed_list_items: [detailedListItem],
                            })
                        )}`;

                        const aTag = document.createElement("a");
                        aTag.setAttribute("href", url);
                        aTag.click();
                    } catch {
                        // Do nothing
                    }

                    // iOS (react-native)
                    this.sendReactNativePostMessage(detailedListItem);

                    // TODO: iOS (JS)

                    safeInvoke(this.props.onAddToListComplete, "ios", true);
                } else {
                    // Android (Native SDK)
                    try {
                        // For Android native, we must trigger the
                        // event by using the available "bridge" method.
                        // @ts-ignore
                        window.AdAdapted.addItemToList({
                            circularId: this.state.urlParams.circularId!,
                            trackingId: item.trackingId,
                            title: item.title,
                            brand: item.brand,
                            category: item.category,
                            upc: item.upc,
                            sku: item.sku,
                            discount: "",
                            image: item.imageUrl,
                        });
                    } catch {
                        // Do nothing
                    }

                    // Android (React Native SDK)
                    this.sendReactNativePostMessage(detailedListItem);

                    // TODO: JS SDK needs to be setup to support circulars.
                    // Android (JS SDK)
                    // try {
                    //     window.parent.postMessage(
                    //         JSON.stringify(detailedListItem),
                    //         "*"
                    //     );
                    // } catch {
                    //     // Do nothing
                    // }

                    safeInvoke(this.props.onAddToListComplete, "android", true);
                }
            } else {
                // User is Out-Of-App
                eventName = EventName.ITEM_QUEUED;

                try {
                    circularApiRequests
                        .scheduleOutOfAppItemAddToList(
                            {
                                appId: this.state.urlParams.app!,
                                circularId: parseInt(
                                    this.state.urlParams.circularId!,
                                    10
                                ),
                                idfa: this.state.urlParams.udid!,
                                item: {
                                    trackingId: item.trackingId,
                                    productTitle: item.title,
                                    productBrand: item.brand,
                                    productCategory: item.category,
                                    productBarcode: item.upc,
                                    productSku: item.sku,
                                    productDiscount: "",
                                    productImage: item.imageUrl,
                                },
                            },
                            this.props.isUnitTest
                        )
                        .then();
                } catch {
                    // Do nothing
                }

                safeInvoke(
                    this.props.onAddToListComplete,
                    this.state.urlParams.plt === "ios" ? "ios" : "android",
                    false
                );
            }
        } else {
            // The user is out-of-network.
            eventName = EventName.REFERRAL;
        }

        // As long as we are not in "preview" mode, allow the GA and Event calls.
        if (!this.state.urlParams.preview) {
            this.sendTrackingEvents(eventName, item.trackingId, index);
        }

        // Update the state with the selected item,
        // so it looks selected in the view.
        this.setState((prevState) => {
            const itemsInList = prevState.itemsInList;

            itemsInList[index] = true;

            return {
                itemsInList,
            };
        });
    }

    /**
     * Send the Circular Telemetry events.
     * @param eventName - The event name for the events being sent.
     * @param itemId - The item ID of the item clicked by the user.
     * @param itemIndex - The index of the item displayed within the circular.
     */
    private sendTrackingEvents(
        eventName: EventName,
        itemId: string | undefined,
        itemIndex: number | undefined
    ): void {
        circularApiRequests
            .sendCircularEvent(
                {
                    udid: this.state.urlParams.udid!,
                    app_id: this.state.urlParams.app,
                    circular_id: this.state.urlParams.circularId!,
                    location_id: this.state.urlParams.storeId!,
                    item_id: itemId,
                    slot: itemIndex,
                    event_name: eventName,
                    submitted_at: new Date().getTime(),
                },
                this.props.isUnitTest
            )
            .then();
    }

    /**
     * Attempts to send a postMessage containing a
     * {@link DetailedListItem} to the react-native SDK.
     * @param detailedListItem - The item to send in the message.
     */
    private sendReactNativePostMessage(
        detailedListItem: DetailedListItem
    ): void {
        try {
            // For react-native, we must trigger the postMessage in a
            // certain way that interacts with the "WebView" component
            // being used to render the circular.
            // @ts-ignore
            window.ReactNativeWebView.postMessage(
                JSON.stringify(detailedListItem)
            );
        } catch {
            // Do nothing
        }
    }
}
