/**
 * Defines the {@link StandardCircular} class.
 * @module
 */
import * as React from "react";
import "./StandardCircular.scss";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import {
    convertCurrencyFromCents,
    formatToCurrency,
    safeInvoke,
} from "../../util";
import { ItemDefinition } from "../../types";

/**
 * Props interface for {@link StandardCircular}.
 */
interface Props {
    /**
     * The list of items to display for the circular.
     */
    items: ItemDefinition[];
    /**
     * Tracks items added to list.
     */
    itemsInList: boolean[];
    /**
     * Tracks the window width.
     */
    windowWidth: number;
    /**
     * Triggered when an item's action button is clicked.
     * @param item - The item clicked.
     * @param index - The index of the item clicked.
     */
    onItemClicked?(item: ItemDefinition, index: number): void;
}

/**
 * State interface for {@link StandardCircular}.
 */
interface State {}

/**
 * Creates the StandardCircular component.
 */
export class StandardCircular extends React.Component<Props, State> {
    /**
     * @inheritDoc
     */
    constructor(props: Props, context?: any) {
        super(props, context);
    }

    /**
     * @inheritDoc
     */
    public render(): JSX.Element {
        return (
            <ImageList
                classes={{
                    root: "StandardCircular",
                }}
                rowHeight={220}
                cols={
                    this.props.windowWidth > 800
                        ? 4
                        : this.props.windowWidth > 600
                        ? 3
                        : 2
                }
            >
                {this.props.items.map((item, idx) => {
                    const displayedIcon = !this.props.itemsInList[idx] ? (
                        <IconButton
                            className="add-action-button"
                            aria-label={`Add ${item.title} to your list.`}
                            onClick={() => {
                                if (item.outOfNetworkActionUrl) {
                                    // The out-of-network action URL will only be defined when
                                    // the "plt" query string param has a value of "other".
                                    window.open(
                                        item.outOfNetworkActionUrl,
                                        "_blank"
                                    );
                                }

                                safeInvoke(this.props.onItemClicked, item, idx);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            className="added-indicator"
                            aria-label={`Item ${item.title} has been added to your list.`}
                            disabled={true}
                        >
                            <CheckIcon />
                        </IconButton>
                    );

                    return (
                        <ImageListItem key={idx} cols={1}>
                            <img
                                className="product-image"
                                src={item.imageUrl}
                                alt={item.title}
                                draggable={false}
                            />
                            <ImageListItemBar
                                className="header-tile-bar"
                                title={
                                    item.price
                                        ? formatToCurrency(
                                              convertCurrencyFromCents(
                                                  item.price.amount
                                              )
                                          )
                                        : ""
                                }
                                actionIcon={
                                    item.isWhileSuppliesLast ? (
                                        <div>While supplies last!</div>
                                    ) : undefined
                                }
                                position="top"
                            />
                            <ImageListItemBar
                                className="footer-tile-bar"
                                title={item.title}
                                subtitle={<div>{item.additionalDetails}</div>}
                                actionIcon={displayedIcon}
                            />
                        </ImageListItem>
                    );
                })}
            </ImageList>
        );
    }
}
