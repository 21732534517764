/**
 * Contains all API request mocks for circular related requests.
 */
import { AxiosResponse } from "axios";
import { circularApiTypes } from "./circularApiTypes";

/**
 * Mocks the API call for getting a circular's data.
 * @returns a promise of an {@link AxiosResponse} of the mocked data.
 */
export function getCircularData(): Promise<
    AxiosResponse<circularApiTypes.responseModels.CircularResponse>
> {
    return new Promise<
        AxiosResponse<circularApiTypes.responseModels.CircularResponse>
    >((resolve) => {
        resolve({
            data: {
                data: {
                    circular: CIRCULAR,
                },
            },
            then: undefined,
            config: {},
            headers: {},
            status: 200,
            statusText: "200",
        });
    });
}

/**
 * Mocks the API call for scheduling a payload add-to-list event for out-of-app.
 * @returns a promise of an {@link AxiosResponse} of the mocked data.
 */
export function scheduleOutOfAppItemAddToList(): Promise<
    AxiosResponse<circularApiTypes.responseModels.ScheduleOutOfAppItemAddToListResponse>
> {
    return new Promise<
        AxiosResponse<circularApiTypes.responseModels.ScheduleOutOfAppItemAddToListResponse>
    >((resolve) => {
        resolve({
            data: {
                data: {
                    status: 200,
                },
            },
            then: undefined,
            config: {},
            headers: {},
            status: 200,
            statusText: "200",
        });
    });
}

/**
 * Mocks the API call for sending a circular event.
 * @returns a promise of an {@link AxiosResponse} of the mocked data.
 */
export function sendCircularEvent(): Promise<
    AxiosResponse<circularApiTypes.responseModels.SendCircularEventResponse>
> {
    return new Promise<
        AxiosResponse<circularApiTypes.responseModels.SendCircularEventResponse>
    >((resolve) => {
        resolve({
            data: {
                data: true,
            },
            then: undefined,
            config: {},
            headers: {},
            status: 200,
            statusText: "200",
        });
    });
}

/**
 * All items for the test circular.
 * Exported for use in unit tests.
 */
export const ITEMS: circularApiTypes.models.CircularItem[] = [
    {
        id: 0,
        trackingId: "tracking-id-1",
        upc: "",
        sku: "",
        title: "Land O'Lakes Margarine",
        brand: "",
        price: {
            amount: 229,
            currency: "USD",
        },
        salePrice: {
            amount: 149,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00003450014000.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-2",
        upc: "",
        sku: "",
        title: "Edy's Vanilla & Chocolate Ice Cream",
        brand: "",
        price: null,
        salePrice: {
            amount: 249,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00004154813385.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-3",
        upc: "",
        sku: "",
        title: "Martinelli's Sparkling Cider",
        brand: "",
        price: {
            amount: 79,
            currency: "USD",
        },
        salePrice: null,
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00004124411250.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-4",
        upc: "",
        sku: "",
        title: "Joy Aloe Drink Mango",
        brand: "",
        price: null,
        salePrice: {
            amount: 199,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00085180200465.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-5",
        upc: "",
        sku: "",
        title: "Deerfield Mixed Vegetables",
        brand: "",
        price: null,
        salePrice: {
            amount: 59,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00085306900571.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-6",
        upc: "",
        sku: "",
        title: "Solo Jack Frost 16oz Cups Red",
        brand: "",
        price: null,
        salePrice: {
            amount: 349,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00004116531801.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-7",
        upc: "",
        sku: "",
        title: "Tabasco Red Pepper Sauce",
        brand: "",
        price: null,
        salePrice: {
            amount: 349,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00001121000015.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-8",
        upc: "",
        sku: "",
        title: "Rice A Roni Chicken/Mushroom",
        brand: "",
        price: {
            amount: 99,
            currency: "USD",
        },
        salePrice: null,
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00001530043025.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-9",
        upc: "",
        sku: "",
        title: "Sapporo Ichiban Shio Ramen",
        brand: "",
        price: null,
        salePrice: {
            amount: 50,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00007618650003.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
    {
        id: 0,
        trackingId: "tracking-id-10",
        upc: "",
        sku: "",
        title: "Think Jerky Sriracha Honey Turkey",
        brand: "",
        price: null,
        salePrice: {
            amount: 149,
            currency: "USD",
        },
        size: "6 oz.",
        featured: false,
        imageUrl:
            "https://s3-us-west-2.amazonaws.com/wowimages.groceryoutlet.com/prod/00085094100625.jpg",
        itemUrl: "https://test-item-url.com",
        category: "",
        createdAt: "2020-10-14T00:04:00Z",
        lastEditedAt: undefined,
        promoId: "",
        promoText: "",
        promoCategory: "",
        promoStartDt: "",
        promoEndDt: "",
    },
];

/**
 * The test location for the circular.
 */
const LOCATION: circularApiTypes.models.StoreLocation = {
    id: 0,
    name: "Test Store 1",
    brandId: 0,
    address1: "123 Sesame Street",
    address2: "",
    city: "New York",
    state: "NY",
    zipcode: "10123",
    country: "US",
    distance: 10,
    latitude: 40.7831,
    longitude: -73.9712,
    createdAt: "2020-10-14T00:04:00Z",
    lastEditedAt: undefined,
};

/**
 * The test circular.
 */
const CIRCULAR: circularApiTypes.models.Circular = {
    id: "TEST-CIRCULAR-ID",
    campaignId: 0,
    flightId: 0,
    logoURL: "https://assets.adadapted.com/circulars/gobm/gobm_logo.png",
    startDate: "2020-10-14T00:04:00Z",
    endDate: "2020-10-21T00:04:00Z",
    createdAt: "2020-10-10T00:04:00Z",
    lastEditedAt: undefined,
    items: ITEMS,
    location: LOCATION,
};
