import * as React from "react";
import { circularApiTypes } from "./api/circularApiTypes";

/**
 * A type that can be null or undefined.
 */
export type nil = null | undefined;

/**
 * A simple pair of typed value and label.
 */
export interface ValueLabelPair<T> {
    /**
     * The value of an item.
     */
    value: T;
    /**
     * The display label for an item.
     */
    label: string;
}

/**
 * Generic interface for a simple object used as a map of type T, keyed by string.
 */
export interface ObjectMap<T = any> {
    [name: string]: T;
}

/**
 * Generic interface for a simple object used as a map of type T, keyed by number.
 */
export interface ObjectMapByNumber<T = any> {
    [name: number]: T;
}

/**
 * Extracts keys of type T that are assignable to string.
 * (omits number and symbol key types).
 */
export type StringKeyOf<T> = Extract<keyof T, string>;

/**
 * Remove types from T that are not assignable to U
 */
export type Filter<T, U> = T extends U ? T : never;

/**
 * Removes null from type T.
 */
export type NonNull<T> = Exclude<T, null>;

/**
 * Removes undefined from type T.
 */
export type NonUndefined<T> = Exclude<T, undefined>;

/**
 * Removes null and undefined from type T.
 */
export type NonNil<T> = Exclude<T, nil>;

/**
 * Utility type that modifies an object type to allow `undefined` on all properties.
 */
export type WithUndefinedProperties<T extends Record<string, never>> = {
    [P in keyof T]: T[P] | undefined;
};

/**
 * Extracts the type of a React component's props from the React component type T.
 * Usage example: type MyComponentProps = ExtractReactPropsType<typeof MyComponent>;
 */
export type ExtractReactPropsType<T> = T extends React.ComponentType<infer P>
    ? P
    : T extends React.Component<infer P>
    ? P
    : never;

/**
 * Defines the data structure of the circular header.
 */
export interface HeaderDefinition {
    /**
     * The store name.
     */
    storeName: string;
    /**
     * The store's address.
     */
    address: string;
    /**
     * The city the store is located.
     */
    city: string;
    /**
     * The state the store is located.
     */
    state: string;
    /**
     * The zip code the store is located.
     */
    zipCode: string;
    /**
     * The latitude of the store.
     */
    latitude: number | null;
    /**
     * The longitude of the store.
     */
    longitude: number | null;
    /**
     * The start date of the circular.
     */
    startDate: string;
    /**
     * The end date of the circular.
     */
    endDate: string;
    /**
     * The brand logo URL.
     */
    logoUrl: string;
}

/**
 * Defines the data structure of an item.
 */
export interface ItemDefinition {
    /**
     * The image URL.
     */
    imageUrl: string;
    /**
     * The item title.
     */
    title: string;
    /**
     * Additional details about the item.
     */
    additionalDetails?: string;
    /**
     * The item price.
     */
    price: circularApiTypes.models.MoneyInput | null;
    /**
     * If true, the item gets a while supplies last message.
     */
    isWhileSuppliesLast?: boolean;
    /**
     * The tracking ID of the item.
     */
    trackingId: string;
    /**
     * The product brand.
     */
    brand: string;
    /**
     * The product category.
     */
    category: string;
    /**
     * The product UPC.
     */
    upc: string;
    /**
     * The retailer's SKU for the product.
     */
    sku: string;
    /**
     * The URL used when an items "add" button is clicked when the circular is loaded out-of-network.
     */
    outOfNetworkActionUrl?: string;
}

/**
 * Enum defining all possible url strings for the Circular Templates API environments.
 */
export enum CircularTemplatesApiEnvironments {
    /**
     * Url for the production environment.
     */
    PRODUCTION = "https://pro.adadapted.com/api",
    /**
     * Url for the production mirror environment.
     */
    PRODUCTION_MIRROR = "https://pro.adadapted.dev/api",
    /**
     * Url for the staging environment.
     */
    STAGING = "https://stg.adadapted.dev/api",
    /**
     * Url for the development environment.
     */
    DEVELOPMENT = "https://dev.adadapted.dev/api",
}

/**
 * Enum defining all possible url strings for the Events API environments.
 */
export enum EventsApiEnvironments {
    /**
     * Url for the production environment.
     */
    PRODUCTION = "https://telemetry.adadapted.com/td.production.circular_events",
    /**
     * Url for the production mirror environment.
     */
    PRODUCTION_MIRROR = "https://telemetry.adadapted.dev/td.sandbox.circular_events",
    /**
     * Url for the staging environment.
     */
    STAGING = "https://telemetry.adadapted.dev/td.sandbox.circular_events",
    /**
     * Url for the development environment.
     */
    DEVELOPMENT = "https://telemetry.adadapted.dev/td.sandbox.circular_events",
}

/**
 * Interface defining inputs to the console.warn that is called when the
 * addItemToList call is made from the definition used when the window.AdAdapted
 * object wasn't available.
 */
export interface AddItemToListConsoleWarnInputs {
    /**
     * The circular ID.
     */
    circularId: string;
    /**
     * The circular tracking ID.
     */
    trackingId: string;
    /**
     * The circular title.
     */
    title: string;
    /**
     * The item brand.
     */
    brand: string;
    /**
     * The item category.
     */
    category: string;
    /**
     * The item UPC.
     */
    upc: string;
    /**
     * The item SKU.
     */
    retailerSku: string;
    /**
     * The item price.
     */
    discount: string;
    /**
     * The item image.
     */
    image: string;
}
